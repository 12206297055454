html {
  height: 100%; }

body {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  overflow: hidden; }

.centre-scale {
  align-items: stretch;
  align-content: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .centre-scale > * {
    flex: 1 1 auto; }

.canvas svg {
  display: block; }
